import {
  inlineView
} from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ProvisionDemos {

  configureRouter(config, router) {
    config.map([{
      route: '',
      name: 'demo-passes',
      title: '',
      moduleId: PLATFORM.moduleName('./demos-list')
    }, {
      route: ':id',
      name: 'demo-details',
      title: 'Demo details',
      moduleId: PLATFORM.moduleName('./demo-details')
    }])
  }
}
