/** @format */
import provisionService from 'services/api/provisionService';
import guestService, {
  Demo
} from 'services/api/guestService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import './provision-demos.scss';

export class ProvisionDemos {
  constructor() {
    this.provisionService = provisionService;
    this.guestService = guestService;

    this.isLoading = true;
    this.demos = [];

    this.gridHeaders = [{
        label: 'name',
        sortable: true,
        sortField: 'name'
      },
      {
        label: 'expires',
        sortable: true,
        sortField: 'expires'
      },
    ];
    this.gridColumns = [{
        type: 'text',
        field: 'name'
      },
      {
        type: 'date',
        field: 'expires',
        cssClass: 'expires'
      },
    ]
    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.itemSelect = this.itemSelect.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;

    this.loadDemos();
  }

  loadDemos() {
    this.isLoading = true;

    this.guestService.get(null, {
      type: 'demo'
    }).then(_demos => {
      this.isLoading = false;
      this.demos = _demos.map(d => {
        let cssClass = '';
        let warningLimit = DTU.subtract(d.expires, 7, 'days');
        if (DTU.isSame(d.expires, DTU.today()) || DTU.isAfter(DTU.today(), d.expires)) {
          cssClass = 'expires-red';
        } else if (DTU.isSame(warningLimit, DTU.today()) || DTU.isAfter(DTU.today(), warningLimit)) {
          cssClass = 'expires-orange';
        }

        d.cssClass = cssClass;
        return d;
      });
    });
  }

  itemSelect(item) {
    this.goToDetails(item);
  }

  onNewClick() {
    let demo = new Demo();
    this.goToDetails(demo);
  }

  goToDetails(demo) {
    let route = 'demo-details';
    const settings = {
      demo: demo,
    };
    this.router.routes.find(x => x.name === route).settings = settings;
    this.router.navigateToRoute(route, {
      id: demo.id || 'new'
    });
  }
}
