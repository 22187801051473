/** @format */

import {
  Router
} from 'aurelia-router';
import {
  DialogService
} from 'aurelia-dialog';
import {
  NewInstance, bindable, computedFrom
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController,
  validateTrigger
} from 'aurelia-validation';
import userService from 'services/api/userService';
import provisionService, {
  Device
} from 'services/api/provisionService';
import assetsService, {
  Asset,
  InactivityUnits
} from 'services/api/assetsService';

import {
  TransferDeviceDialog
} from './transfer-device-dialog/transfer-device-dialog';

import {
  LinkAssetDialog
} from './link-asset-dialog/link-asset-dialog';

export class DeviceDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }

  constructor(_Router, _ValidationController, _DialogService) {
    this.router = _Router;
    this.dialogService = _DialogService;
    this.validationController = _ValidationController;
    //
    this.assetsService = assetsService;
    this.provisionService = provisionService;
    this.userService = userService;
    this.inactivityUnits = InactivityUnits;

    this.device = new Device({
      accountId: this.provisionService.selectedAccountId,
      msisdnObj: {}
    });
    this.dvrCheck = null;

    this.loading = false;
    this.errorMessage = null;
    this.newItemMappings = null;
    this.newItemDvr = null;
    this.accountId = null;

    this.assetLinkContext = {
      selectedAsset: null,
      id: null
    };
    this.linkTo = {
      id: null,
      sources: [],
      wasLinked: false
    }

    this.inactivityMonitoring = false;
    this.archive = false;

    this.showAssetSelect = false;
    this.dvrFeatures = [];
    this.onDvrListChanged = this.onDvrListChanged.bind(this);
    this.onMappingsChanged = this.onMappingsChanged.bind(this);
    this.onModelChanged = this.onModelChanged.bind(this);

    this.isSuperAdmin = false;

    this.rules = ValidationRules
      .ensure(o => o.uuId)
      .required()
      .minLength(12).when(o => !o.id)
      .withMessage('12 to 64 characters long.')
      .maxLength(64).when(o => !o.id)
      .withMessage('12 to 64 characters long.')
      .matches(/(^[a-fA-F0-9-]+)/).when(o => !o.id)
      .withMessage('Only letters and numbers and dashes. Should follow this pattern ^[a-fA-F0-9-]')
      .ensure(o => o.iccid)
      .required()
      .minLength(2).withMessageKey('tooShort')
      .maxLength(20).withMessageKey('tooLong')
      .ensure(o => o.msisdnObj).required()
      .ensure(o => o.msisdnObj).satisfies((value, o) => value.number).when(o => o.msisdnObj).withMessageKey('required')
      .ensure(o => o.msisdnObj).satisfies((value, o) => value.number.length > 5).when(o => o.msisdnObj && o.msisdnObj.number).withMessageKey('tooShort')
      .ensure(o => o.msisdnObj).satisfies((value, o) => value.number.length < 15).when(o => o.msisdnObj && o.msisdnObj.number).withMessageKey('tooLong')
      .ensure(o => o.msisdnObj).satisfies((value, o) => value.countryCode).when(o => o.msisdnObj).withMessageKey('required')
      .rules;
  }

  activate(params, cfg) {
    this.linkTo.wasLinked = false;
    this.loadDevice(params.id);

    this.userService
      .getSelf()
      .then(user => (this.isSuperAdmin = user.isSuperAdmin));
  }

  loadDevice(id) {
    this.showAssetSelect = true;

    if (id > 0) {
      this.loading = true;
      this.provisionService
        .getDevices(id)
        .then(([device]) => {
          if (device) {
            this.device = device;
            this.showAssetSelect = !this.device.assetId;
            this.loadDeviceFeatures(device.modelId);
            this.checkAssetLink(device.assetId);

            this.inactivityMonitoring = (this.device.properties && this.device.properties.allowedInactivity) ? true : false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.navigateBack();
        });
    }
  }

  checkAssetLink = (assetId) => {
    this.assetsService.getById(assetId).then(asset => {
      this.assetLinkContext.id = assetId;
      if (!this.showAssetSelect && asset && asset.hasOwnProperty('linkedTo')) {
        this.assetLinkContext = {
          selectedAsset: asset.linkedTo,
        }
      }
    })
  }

  loadDeviceFeatures(modelId) {
    if (modelId <= 0) return;

    this.provisionService.getModels(modelId).then(model => {
      this.features = model.features;

      this.getFeatures;
    });
  }

  @computedFrom("device.features")
  get getFeatures() {
    if (this.device.features && this.device.features.length > 0) {
      this.device.features.filter(f => {
        if (f.id === 'dvr' && f.metadata) {
          this.dvrFeatures = f.metadata.channels
        } else {
          return []
        }
      })
    }
  }

  onSave() {
    this.save().then(device => {
      if (device) {
        this.navigateBack();
      }
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onToggleEnabled() {
    this.archive = this.archive && !this.device.isEnabled;
  }

  onMappingsChanged(mappings) {
    this.newItemMappings = mappings;
  }

  onDvrListChanged(newValue) {
    this.device.features.forEach(element => {
      if (element.id === 'dvr') {
        element.metadata = { channels: newValue };
      }
    });
  }

  openLinkDialog() {
    this.dialogService
      .open({
        viewModel: LinkAssetDialog,
        model: {
          assetlink: this.assetLinkContext.selectedAsset,
          id: this.assetLinkContext.id,
          accountId: this.device.accountId,
          features : this.device.features || null
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.linkTo = result.output
        } else {
          this.linkTo = null;
        }
      });
  }

  onModelChanged(id) {
    this.loadDeviceFeatures(id);
  }

  transferDevice(deviceId, accountId, comment) {
    if (accountId && deviceId) {
      return this.provisionService
        .transferDevice(deviceId, accountId, comment)
        .then(result => {
          return true
        })
        .catch(err => {
          return err
        });
    } else {
      return false;
    }
  }

  onTransfer() {
    this.dialogService
      .open({
        viewModel: TransferDeviceDialog,
        model: {
          deviceId: this.device.id,
          transferDevice: this.transferDevice.bind(this)
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.navigateBack();
        }
      });
  }

  save() {
    return this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;
      this.device.ioMappings = this.newItemMappings;

      //when `/new` route, this is empty
      this.device.accountId =
        this.device.accountId || this.provisionService.selectedAccountId;

      return this.updateDevice()
        .then(this.updateAsset.bind(this))
        .then(device => {
          if (this.linkTo && this.linkTo.wasLinked && this.linkTo.id) {
            this.linkingAssetManager();
          }
          return device
        })
        .then(device => {
          this.loading = false;
          return device;
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  updateDevice() {

    return this.provisionService
      .updateDevice(this.device, this.device.isEnabled)
      .then(device => {
        return this.archive ? this.archiveAsset(device) : device;
      })
      .then(updated => Object.assign({}, this.device, updated));
  }

  archiveAsset(device) {
    return this.provisionService.archiveAsset(device.assetId).then(_ => device);
  }

  onEnabledCheckedInactivityMonitoring(event) {
    if (typeof (this.device.properties) == "undefined") {
      this.device.properties = {
        allowedInactivity: null
      }
    }
    if (!event.target.checked || typeof (this.device.properties.allowedInactivity) == "undefined") {
      this.device.properties.allowedInactivity = null;
    }
  }

  mergeInactivityMonitoring(event) {
    if (this.inactivityMonitoring) {
      if (!this.device.properties) {
        this.device.properties = {};
      }
      if (event.detail) {
        this.device.properties.allowedInactivity = event.detail.value + event.detail.unit;
      }
    }
  }

  //check if needs to create new asset
  updateAsset(device) {
    //create a new one
    if (device.assetId === 0) {
      return this.assetsService
        .update(
          new Asset({
            name: device.uuId,
            deviceId: device.id,
            accountId: device.accountId
          })
        )
        .then(asset => {
          device.assetId = asset.id;
          device.assetName = asset.name;
          return device;
        });
    } else {
      return Promise.resolve(device);
    }
  }

  linkingAssetManager() {
    switch (this.linkTo.type) {
      case 'add':
        this.assetsService.addLink(this.linkTo, this.device.assetId);
        break;
      case 'delete':
        this.assetsService.deleteLink(this.device.assetId);
        break;
    }
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.device,
        rules: this.rules,
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    this.router.navigateToRoute('devices');
  }
}
