/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import provisionService from 'services/api/provisionService';
import userService from 'services/api/userService';

import eventService, {
  EventsList
} from 'services/eventService';

import {
  TransferDeviceDialog
} from './transfer-device-dialog/transfer-device-dialog';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';


import './provision-devices.scss';

const DEVICE_INACTIVE_CSSCLASS = 'inactive';

export class DevicesList {
  static inject() {
    return [DialogService];
  }

  constructor(_DialogService) {
    this.dialogService = _DialogService;
    this.provisionService = provisionService;
    this.userService = userService;

    this.gridHeaders = [{
        label: 'uuId',
        sortable: true,
        sortField: 'uuId'
      },
      {
        label: 'asset',
        sortable: true,
        sortField: 'assetName'
      },
      {
        label: 'model',
        sortable: true,
        sortField: 'fullModelName'
      },
      {
        label: 'ICCID',
        sortable: true,
        sortField: 'iccid'
      },
      {
        label: 'MSISDN',
        sortable: true,
        sortField: 'msisdn'
      }
    ];

    this.gridColumns = [{
        type: 'text',
        field: 'uuId'
      },
      {
        type: 'text',
        field: 'assetName'
      },
      {
        type: 'text',
        field: 'fullModelName'
      },
      {
        type: 'text',
        field: 'iccid'
      },
      {
        type: 'text',
        field: 'msisdn'
      }
    ];

    this.multiSelectActions = [{
        name: 'edit',
        icon: 'edit',
        action: this.multiSelectEdit.bind(this)
      },
      {
        name: 'enable',
        label: 'enable',
        dropdown: true,
        action: this.multiSelectEnable.bind(this)
      },
      {
        name: 'disable',
        label: 'disable',
        dropdown: true,
        action: this.multiSelectDisable.bind(this)
      },
      {
        name: 'transfer',
        label: 'transfer',
        dropdown: true,
        action: this.multiSelectTransfer.bind(this)
      }
    ];

    this.sortProperties = {
      field: 'uuId',
      reverse: false
    }

    this.isLoading = false;
    this.devices = [];
    this.selectedAccountId = null;
    this.isAdmin = false;

    this.itemSelect = this.itemSelect.bind(this);

    eventService.subscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  detached() {
    eventService.unsubscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  @computedFrom('devices')
  get devicesList() {
    return this.devices;
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;

    let accountId = this.provisionService.selectedAccountId;
    if (accountId) {
      this.onAccountChanged(accountId);
    }

    this.isAdmin = this.userService.hasRole('admin');
  }

  onAccountChanged = id => {
    if (id) {
      this.selectedAccountId = id;
      this.loadDevices(id);
    }
  };

  loadDevices(id) {
    this.isLoading = true;

    this.provisionService.getDevices(null, id).then(devices => {
      this.devices = (devices || []).map(d => {
        d.cssClass = `${!d.isEnabled ? DEVICE_INACTIVE_CSSCLASS : ''}`;
        return d;
      });
      this.isLoading = false;
    })
  }

  itemSelect(device) {
    this.goToDetails(device);
  }

  onNewClick() {
    this.goToDetails();
  }

  goToDetails(device) {
    let route = 'device-details';
    this.router.routes.find(x => x.name === route).settings = {
      device
    };
    this.router.navigateToRoute(route, {
      id: device ? device.id : 'new'
    });
  }

  goToBulkDetails(devices) {
    let route = 'device-bulk-details';
    this.router.routes.find(x => x.name === route).settings = {
      devices
    };
    this.router.navigateToRoute(route, {
      action: devices.length ? 'edit' : 'new'
    });
  }

  multiSelectEdit(event) {
    let selectedDevices = this.devices.filter(device => (device.selected))
    let selectedDevicesId = selectedDevices.map(device => device.id)
    this.goToBulkDetails(selectedDevicesId)
  }

  multiSelectTransfer() {
    let selectedDevices = this.devices.filter(device => (device.selected))
    let selectedDevicesId = selectedDevices.map(device => device.id)
    this.dialogService
      .open({
        viewModel: TransferDeviceDialog,
        model: {
          deviceId: selectedDevicesId,
          transferDevice: this.transferDevice.bind(this)
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.loadDevices(this.selectedAccountId);
        }
      });
  }

  transferDevice(devices, accountId, comment) {
    if (accountId && devices.length) {
      return this.provisionService
        .transferDevices(devices, accountId, comment)
        .then(result => {
          return true
        })
        .catch(err => {
          return err
        });
    } else {
      return false;
    }
  }

  multiSelectEnable() {
    let selectedDevices = this.devices.filter(device => (device.selected))
    var selectedDevicesId = selectedDevices.map(device => device.id)
    this.dialogService
      .open({
        viewModel: PromptDialog,
        model: {
          title: 'enable_devices',
          question: true,
          message: 'enable_device_message',
          confirm: 'enable'
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.provisionService
            .enableDevices(selectedDevicesId)
            .then(result => {
              this.loadDevices(this.selectedAccountId);
              return result
            })
            .catch(err => {
              return err
            });
        }
      });
  }

  multiSelectDisable() {
    let selectedDevices = this.devices.filter(device => (device.selected))
    var selectedDevicesId = selectedDevices.map(device => device.id)
    this.dialogService
      .open({
        viewModel: PromptDialog,
        model: {
          title: 'disable_devices',
          question: true,
          message: 'disable_device_message',
          confirm: 'disable',
          checkbox: {
            visible: true,
            value: false,
            label: 'archive_asset'
          }
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          var archiveAsset = result.output.checkbox;
          this.provisionService
            .disableDevices(selectedDevicesId, archiveAsset)
            .then(result => {
              this.loadDevices(this.selectedAccountId);
              return result
            })
            .catch(err => {
              return err
            });
        }
      });
  }
}
