/** @format */

import provisionService from 'services/api/provisionService';
import translationsService from 'services/api/translationsService';
import { Router } from 'aurelia-router';

export class ProvisionTranslationList {
  static inject() {
    return [Router];
  }

  constructor(_Router) {
    this.router = _Router;
    this.route = {};
    //
    this.provisionService = provisionService;
    this.translationsService = translationsService;
    //
    this.isLoading = false;
    this.detailsLoading = false;
    this.translations = [];
    this.selectedItem = null;
    this.errorMessage = '';

    this.sortProperties = {
      field: 'id',
      reverse: false
    }

    this.itemSelect = this.itemSelect.bind(this);
  }

  //this runs also when returnning from details route
  attached() {
    this.errorMessage = '';
    this.isLoading = true;
    this.translationsService.getTranslations().then(res => {
      this.isLoading = false;
      this.translations = res;
    });
  }

  activate(params, config) {
    this.route = config;
  }

  itemSelect(item) {
    this.goToDetails(item);
  }

  onNewClick() {
    this.goToDetails();
  }

  goToDetails(translation) {
    let route = 'translation-details';
    this.router.routes.find(x => x.name === route).settings = translation;
    if (translation) {
      var  param = window.btoa(translation.cultureId + '/' + translation.id)
    } else {
      var param = 'new';
    }
    this.router.navigateToRoute(route, { id: param });
  }
}
