/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import provisionService from 'services/api/provisionService';
import guestService, {
  Demo
} from 'services/api/guestService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';
import {
  apiConfig
} from 'envconfigs/apiConfig';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  Router
} from 'aurelia-router';

import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';
import {
  ShareDialog
} from './partials/share-dialog/share-dialog';

export class DemoDetails {
  static inject() {
    return [DialogService, Router, NewInstance.of(ValidationController)];
  }

  constructor(_DialogService, _Router, _ValidationController) {
    this.dialogService = _DialogService;
    this.router = _Router;
    this.validationController = _ValidationController;

    this.guestService = guestService;
    this.provisionService = provisionService;

    this.rules = ValidationRules
      .ensure(a => a.name).required().minLength(2).withMessageKey('tooShort')
      .ensure(a => a.expires).required()
      .rules;

    this.demo = null;
    this.loading = false;
    this.errorMessage = null;

    this.copyTrailIcon = {
      show: true,
      active: true,
      class: 'copy-action',
      icon: 'fi-content-copy',
      action: this.copyAction.bind(this)
    }
  }

  activate(params, cfg) {
    let {
      demo
    } = cfg.settings;
    this.demo = demo;
    this.loadData();
  }

  loadData() {
    if (!this.demo || !this.demo.id) return;

    this.loading = true;
    this.guestService.get(this.demo.id).then(demo => {
      this.demo = demo;
      this.loading = false;
    }).catch(e => {
      this.navigateBack()
    })
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      return this.guestService
        .update(this.demo)
        .then(() => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.demo,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.reject(err);
      });
  }

  onCancel() {
    this.navigateBack();
  }

  onRenew() {
    this.demo.expires = DTU.add(DTU.startOf(DTU.today()), 30, 'days');
    this.guestService
      .update(this.demo)
      .then(success => {
        if (success) {
          this.loading = false;
          this.errorMessage = 'demo_pass_renewed_sucess_message'
        }
      })
      .catch(err => {
        this.errorMessage = err.message;
        this.loading = false;
      });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.guestService
        .delete(this.demo)
        .then(r => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  openPrompt() {
    const title = 'delete_demo_pass';
    const question = true;
    const message = 'delete_demo_pass_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  navigateBack() {
    this.router.navigateToRoute('demo-passes');
  }

  copyAction(e) {
    let element = e.target.parentElement.parentElement.querySelector('input');
    element.select();
    document.execCommand('copy');
    this.errorMessage = 'copied_to_clipboard'
  }

  onShare() {
    this.openShareDialog().then(output => {
      if (!output) return;
      this.loading = true;
      this.guestService
        .actions(this.demo, {
          action: 'share',
          emailAddresses: output.emailAddresses
        })
        .then(response => {
          this.loading = false;
          this.errorMessage = 'demo_pass_shared_success_message';
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  openShareDialog() {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: ShareDialog
        })
        .whenClosed(result => {
          if (!result.wasCancelled) {
            resolve(result.output);
          }
        });
    });
  }

  @computedFrom('demo')
  get demoAccessLink() {
    if (!this.demo || !this.demo.id) return null;

    let urlParams = {
      redirectUrl: window.location.origin,
      email: 'guest@fonixtelematics.com',
      password: this.demo.accessKey
    }
    
    return `${apiConfig.fonixAccount}#/${window.btoa(JSON.stringify(urlParams))}`;
  }
}
