/** @format */

import {
  Router
} from 'aurelia-router';
import {
  DialogService
} from 'aurelia-dialog';
import {
  computedFrom
} from 'aurelia-framework';

const DEVICE_INACTIVE_CSSCLASS = 'inactive';

import userService from 'services/api/userService';
import provisionService, {
  Device
} from 'services/api/provisionService';
import assetsService, {
  Asset,
  InactivityUnits
} from 'services/api/assetsService';
import eventService, {
  EventsList
} from 'services/eventService';

export class DeviceBulkDetails {
  static inject() {
    return [Router, DialogService];
  }

  constructor(_Router, _DialogService) {
    this.router = _Router;
    this.dialogService = _DialogService;
    //
    this.assetsService = assetsService;
    this.provisionService = provisionService;
    this.userService = userService;
    this.inactivityUnits = InactivityUnits;

    this.devices = [];
    this.selectedDevices = [];
    this.data = {
      properties: {
        allowedInactivity: null
      }
    };
    this.loading = false;
    this.error = null;
    this.selectedAccountId = null;
    this.inactivityMonitoring = false;

    eventService.subscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  activate(params, cfg) {
    this.selectedDevices = (cfg.settings && cfg.settings.devices) ? cfg.settings.devices : [];

    let accountId = this.provisionService.selectedAccountId;
    if (accountId) {
      this.onAccountChanged(accountId);
    }
  }

  detached() {
    eventService.unsubscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  onAccountChanged = id => {
    if (id) {
      this.selectedAccountId = id;
      this.loadDevices(id);
    }
  };

  loadDevices(id) {
    this.isLoading = true;

    this.provisionService.getDevices(null, id).then(devices => {
      this.devices = (devices || []).map(d => {
        d.cssClass = `${!d.isEnabled ? DEVICE_INACTIVE_CSSCLASS : ''}`;
        d.name = d.uuId;
        return d;
      });
      this.isLoading = false;
    })
  }

  onSave() {
    this.save().then(device => {
      if (device) {
        this.navigateBack();
      }
    });
  }

  onCancel() {
    this.navigateBack();
  }

  save() {
    return this.onValidate().then(valid => {
      if (valid) {
        this.loading = true;

        return this.updateDevices(this.selectedDevices)
          .then(device => {
            this.loading = false;
            return device;
          })
          .catch(err => {
            this.error = err.message;
            this.loading = false;
            return null;
          });
      }
    });
  }

  updateDevices(deviceIds) {
    var devices = Object.assign({
      ids: deviceIds
    }, this.data);
    return this.provisionService
      .updateDevices(devices)
      .then(updated => Object.assign({}, devices, updated));
  }

  onEnabledCheckedInactivityMonitoring(event) {
    if (!event.target.checked) {
      this.data.properties.allowedInactivity = null
    }
  }

  mergeInactivityMonitoring(event) {
    if (this.inactivityMonitoring) {
      if (event.detail) {
        this.data.properties.allowedInactivity = event.detail.value + event.detail.unit;
        return;
      }
    }
    this.data.properties.allowedInactivity = null;
  }

  onValidate() {
    let result = (this.selectedDevices && this.selectedDevices.length) ? true : false;
    return Promise.resolve(result);
  }

  navigateBack() {
    this.router.navigateToRoute('devices');
  }
}
