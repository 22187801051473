/** @format */

import {
  DialogController
} from 'aurelia-dialog';
import provisionService from 'services/api/provisionService';
import userService from 'services/api/userService';

import './transfer-device-dialog.scss';

export class TransferDeviceDialog {
  static inject() {
    return [DialogController];
  }
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    //
    this.provisionService = provisionService;
    this.userService = userService;
    //
    this.isSuperAdmin = false;
    this.showResellers = true;
    this.resellers = [];
    this.accounts = [];
    this.selectedResellerId = null;
    this.selectedAccountId = null;

    this.deviceId = null;
    this.comment = null;

    this.onResellerListSelected = this.onResellerListSelected.bind(this);
    this.onAccountListSelected = this.onAccountListSelected.bind(this);

    this.loading = false;
    this.loadingResellers = false;
    this.loadingAccounts = false;
  }

  activate(model) {
    this.accounts = [];
    this.userService.getSelf().then(self => {
      this.isSuperAdmin = self.isSuperAdmin;
      this.selectedResellerId = self.resellerId;
    });

    this.deviceId = model.deviceId;
    this.transferDevice = model.transferDevice.bind(this);
    this.loadResellers();
  }

  loadAccounts(resellerId) {
    this.loadingAccounts = true;
    this.accounts = [];
    this.userService.getAccounts(null, resellerId).then(accounts => {
      this.accounts = accounts.map(a => {
        a.disabled = a.id === this.accountId;
        return a;
      });
      this.selectedAccountId = null;
      this.loadingAccounts = false;
    });
  }

  loadResellers() {
    this.loadingResellers = true;
    this.userService.getResellers().then(resellers => {
      if (!this.isSuperAdmin && resellers && resellers.length > 0) {
        resellers = resellers.filter(item => item.id == this.selectedResellerId);
        this.accountsLounding(resellers[0]);
        this.showResellers = false;
      }
      this.resellers = resellers;
      this.selectedResellerId = null;
      this.selectedAccountId = null;
      this.loadingResellers = false;
    });
  }

  onResellerListSelected([reseller]) {
    if (reseller && this.selectedResellerId !== reseller.id) {
      this.accountsLounding(reseller);
    }
  }
  
  accountsLounding(reseller) {
    this.selectedResellerId = reseller.id;
    this.loadAccounts(this.selectedResellerId);
  }

  onAccountListSelected([account]) {
    if (account && account.id !== this.selectedAccontId) {
      this.selectedAccountId = account.id;
    }
  }

  onConfirm() {
    this.loading = true;
    this.transferDevice(this.deviceId, this.selectedAccountId, this.comment).then(result => {
      if (result === true) {
        this.dialogController.ok(result);
      } else {
        this.err = result.message || null;
        this.loading = false;
      }
    })
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
