/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  Router
} from 'aurelia-router';

import provisionService from 'services/api/provisionService';
import translationsService from 'services/api/translationsService';

import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class TranslationDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }

  constructor(_Router, _ValidationController, _DialogService) {
    this.router = _Router;
    this.validationController = _ValidationController;

    this.dialogService = _DialogService;

    this.provisionService = provisionService;
    this.translationsService = translationsService;

    this.rules = ValidationRules
      .ensure(d => d.id).required().minLength(2).withMessageKey('tooShort')
      .ensure(d => d.value).required().minLength(2).withMessageKey('tooShort')
      .rules;

    this.translation = null;
    this.loading = false;
    this.errorMessage = null;
    this.isEdit = false;
  }

  activate(params, cfg) {
    this.translation = cfg.settings || {};
    
    this.loadTranslation(params.id);
  }

  loadTranslation(params) {
    if (params && params != 'new') {
      this.isEdit = true;
      this.loading = true;
      if (Object.keys(this.translation).length === 0) {
        let urlAttributes = window.atob(params).split('/');
        this.translationsService.getTranslation(urlAttributes[0], urlAttributes[1]).then(([translation]) => {
          if (!translation) {
            this.navigateBack();
            return;
          }
          this.translation = translation;
          this.loading = false;
        }).catch(() => this.navigateBack());
      } else {
        this.loading = false;
      }
    }
  }

  navigateBack() {
    this.router.navigateToRoute('translations');
  }

  onCancel() {
    this.navigateBack();
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.translation,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;
      if (this.isEdit) {
        return this.translationsService
          .update(this.translation)
          .then(translation => {
            this.translation = translation;
            this.loading = false;
            this.navigateBack();
          }).catch(err => {
            this.loading = false;
            throw (err);
          });
      } else {
        return this.translationsService
          .create(this.translation)
          .then(translation => {
            this.translation = translation;
            this.loading = false;
            this.navigateBack();
          }).catch(err => {
            this.loading = false;
            throw (err);
          });
      }
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      let translation = this.translation;
      this.loading = true;

      this.translationsService
        .delete(translation)
        .then(() => {
          this.translation.deleted = true;
          this.loading = false;
          this.navigateBack();
        })
        .catch(e => {
          this.err = e.message;
          this.loading = false;
        });
    });
  }

  openPrompt() {
    const title = 'delete_translation';
    const question = true;
    const message = null;
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
