/** @format */

import {
  DialogController
} from 'aurelia-dialog';

import assetsService from 'services/api/assetsService';

import './link-asset-dialog.scss';

export class LinkAssetDialog {
  static inject() {
    return [DialogController];
  }
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    this.assetsService = assetsService;

    //

    this.assetlink = null;
    this.name = null;
    this.selectedAsset = null;
    this.assets = [];
    this.source = {
      data: null,
      video: null
    };

    this.hasVideo = null;
    this.hasDvr = true;

    this.loadingAssets = false;

    this.onAssetSelected = this.onAssetSelected.bind(this);
  }

  activate(model) {
    this.assetlink = model.assetlink;
    this.loadAssets(model.id, model.accountId);
    !this.assetlink ? this.checkFeatures(model.features) : '';
  }

  checkFeatures(features) {
    let dvr = false;
    if (features && features.length > 0) {
      dvr = features.find(({ id, metadata }) => id === 'dvr' && metadata)
    }
    this.hasDvr = dvr ? true : false;
  }

  loadAssets(id, accountId) {
    this.loadingAssets = true;

    if (this.assetlink && this.assetlink.id) {
      this.assetsService.getById(this.assetlink.id).then(a => {
        this.name = a.name;
        this.loadingAssets = false;
      })
    } else {
      this.assetsService.get(null, { accountId: accountId }).then(assets => {
        this.assets = assets.filter(a => a.id != id);
        this.loadingAssets = false;
      })

    }
  }

  onAssetSelected([asset]) {
    if (asset && asset.id) {
      this.selectedAsset = asset;
      let video = asset.links ? asset.links.find(element => element.sources.includes('video')) : null;
      this.hasVideo = video ? true : false;
    }
  }

  onConfirm(type, id) {
    let sources = [];
    for (const [key, value] of Object.entries(this.source)) {
      value ? sources.push(key) : '';
    }

    const payload = {
      type: type,
      id: id,
      sources: sources,
      wasLinked: true
    }
    this.dialogController.ok(payload);
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
