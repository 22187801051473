import { bindable } from 'aurelia-framework';
import provisionService from 'services/api/provisionService';


/**
 * TODO:
 * remove two-way binding of entityID in place of one-way binding with a second property for forms
 * two-way binding is bad! (and it's causing all sorts of problems and uneeded re-renders)
 */

export class SelectEntity {
  @bindable type;
  @bindable filter;
  @bindable entityId;
  @bindable label;
  @bindable waitFilter;
  @bindable disabled;
  @bindable cssClass;

  @bindable onItemSelect;

  constructor() {
    this.provisionService = provisionService;
    this.entities = [];

    this.entityId = null;
    this.filter = null;
    this.cssClass = '';

    this.waitFilter = false;

    this.onSelectChanged = this.onSelectChanged.bind(this);
  }


  attached() {
    this.ready = true;
    this.hasLoaded = false;

    if (!this.hasLoaded) {
      if (this.waitFilter && this.filter < 1) return;
      this.loadEntities();
    }
  }

  entityIdChanged(id) {
    if(this.waitFilter && this.filter < 1) return;
    
    if (this.ready && id) {
      this.selectEntity(id, this.entities);
    }
  }

  typeChanged(type) {
    if (this.ready && type) {
      this.loadEntities();
    }
  }

  filterChanged(filter) {
    if (this.ready && filter) {
      this.loadEntities();
    }
  }

  loadEntities() {
    if (this.ready) {
      this.provisionService.getEntity(this.type, null, this.filter)
        .then(res => {
          this.hasLoaded = true;
          let entities = res;

          let defaultSel = this.entityId;
          let foundDefault = defaultSel && entities.find(x => x.id === defaultSel);          
          if(!foundDefault && res[0]) {
            defaultSel = res[0].id;
          }

          //select default and fire event
          setTimeout(() => {
            this.selectEntity(defaultSel, entities);
            this.entities = entities;          
          }, 0);
        });
    }
  }

  selectEntity(id, entities) {
    if (id && this.ready && this.hasLoaded) {
      let found = entities.filter(x => x.id === id)[0];
      entities.forEach(e => e.selected = found ? found.id === e.id : e.default);
      
      this.onSelectChanged(entities.find(x => x.selected));
    }
  }

  onSelectChanged(value) {
    let id = value ? value.id : null;

    let changed = this.entityId != id;
    if (changed) {
      let old = this.entityId;
      this.entityId = id;
      
      if (this.onItemSelect) {
        this.onItemSelect(id, old);
      }
    }
  }
}
