/** @format */
import provisionService from 'services/api/provisionService';
import userService, { Account } from 'services/api/userService';
import eventService, { EventsList } from 'services/eventService';

import './provision-accounts.scss';

export class ProvisionAccounts {
  constructor() {
    this.provisionService = provisionService;
    this.userService = userService;

    this.isLoading = true;
    this.accounts = [];
    this.permissionsList = [];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.filterProperties = {
      showDisabled: false
    }

    this.advancedActions = [{
      name: 'display_suspended_accounts',
      checkbox: true,
      value: this.filterProperties.showDisabled,
      action: this.showDisabledChanged.bind(this)
    }]

    this.qGetAccountDetails = Promise.resolve(null);
    this.itemSelect = this.itemSelect.bind(this);

    this.userService.getPermissionsList().then(permissions => {
      this.permissionsList = permissions;
    });
  }

  attached() {
    eventService.subscribe(
      EventsList.ProvisionResellerChanged,
      this.onResellerChanged
    );
  }

  detached() {
    eventService.unsubscribe(
      EventsList.ProvisionResellerChanged,
      this.onResellerChanged
    );
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;

    let resellerId = this.provisionService.selectedResellerId;
    if (resellerId) {
      this.onResellerChanged(resellerId);
    }
  }

  onResellerChanged = id => {
    if (!id) return;

    this.loadAccounts(id);
    this.resellerId = id;
    this.qGetAccountDetails = this.provisionService.getAccounts(id);
  };

  loadAccounts(resellerId) {
    this.isLoading = true;
    
    this.provisionService.getAccounts(null, resellerId, this.filterProperties.showDisabled).then(accounts => {
      this.accounts = accounts;
      this.isLoading = false;
    });
  }

  showDisabledChanged(button) {
    if (button) {
      this.filterProperties.showDisabled = button.value;
    }
    let resellerId = this.provisionService.selectedResellerId;
    this.loadAccounts(resellerId);
  }

  itemSelect(item) {
    this.goToDetails(item);
  }

  onNewClick() {
    this.qGetAccountDetails.then(res => {
      let defaultSettings = res[0] ? res[0].settings : {};
      let newAcc = new Account({
        resellerId: this.resellerId,
        settings: defaultSettings
      });
      this.goToDetails(newAcc);
    });
  }

  goToDetails(account) {
    let route = 'account-details';
    const settings = {
      account: account,
      permissionsList: this.permissionsList
    };
    this.router.routes.find(x => x.name === route).settings = settings;
    this.router.navigateToRoute(route, { id: account.id || 'new' });
  }
}
