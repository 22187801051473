import { inlineView } from 'aurelia-framework';
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ProvisionResellers {
  configureRouter(config, router) {
    const routes = [{
      route: '',
      name: 'resellers',
      title: '',
      moduleId: PLATFORM.moduleName('./resellers-list')
    },{
      route: ':id',
      name: 'reseller-details',
      title: 'Reseller details',
      moduleId: PLATFORM.moduleName('./reseller-details')
    }];
    config.map(routes);
  }
}
