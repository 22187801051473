import {
  inlineView
} from 'aurelia-framework'
import {PLATFORM} from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ProvisionDevices {

  configureRouter(config, router) {
    config.map([{
        route: '',
        name: 'devices',
        title: '',
        moduleId: PLATFORM.moduleName('./devices-list')
      },
      {
        route: ':id',
        name: 'device-details',
        title: 'Device details',
        moduleId: PLATFORM.moduleName('./device-details')
      },
      {
        route: '/bulk/:action',
        name: 'device-bulk-details',
        title: 'Devices details',
        moduleId: PLATFORM.moduleName('./device-bulk-details')
      }
    ])
  }
}
