/** @format */

import { bindable } from 'aurelia-framework';
import assetsService from 'services/api/assetsService';

import { TranslationService } from 'services/translationService';

export class SelectAsset {
  static inject() {
    return [TranslationService];
  }

  @bindable assetId;
  @bindable accountId;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    //
    this.assetService = assetsService;
    //
    this.assets = [];
    this.onAssetSelect = this.onAssetSelect.bind(this);
  }

  bind() {
    this.loadAssets(this.accountId);
  }

  assetIdChanged(id) {
    if (id) {
      this.selectAsset(id);
    }
  }

  accountIdChanged(id) {
    this.loadAssets(id);
  }

  loadAssets(accountId) {
    this.assetService
      .get(null, { accountId: accountId, unassigned: true })
      .then(assets => {
        this.ready = true;

        this.assets = assets.map(x => {
          return {
            id: x.id,
            name: x.name
          };
        });

        this.assets.unshift(
          {
            id: -1,
            name: this.ts.getCap('asset'),
            default: true
          },
          {
            id: 0,
            name: this.ts.getCap('assets_autocreate')
          }
        );
      });
  }

  selectAsset(id) {
    if (this.ready) {
      let updatedAssets = this.assets.map(x => {
        return {
          ...x,
          selected: x.id === id
        };
      });
      this.assets = updatedAssets;
      return true;
    }
  }

  onAssetSelect(item) {
    this.assetId = item.id;
  }
}
