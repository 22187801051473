/** @format */
import { Router } from 'aurelia-router';
import { computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { PromptDialog } from 'components/prompt-dialog/prompt-dialog';

import poiService from 'services/api/areasService';
import provisionService from 'services/api/provisionService';
import eventService, { EventsList } from 'services/eventService';


import './provision-poi-import.scss';

const POI_FIELDS = ['name', 'address', 'latitude', 'longitude', 'groupName'];

export class ProvisionPoiImport {
  static inject() {
    return [Router, DialogService];
  }
  constructor(_Router, _DialogService) {
    this.router = _Router;
    this.dialogService = _DialogService;

    //
    this.poiService = poiService;
    //

    this.fileInput = null;
    this.loadingUpload = false;

    this.fileMappings = null;
    this.poiMappings = null;

    this.previewResult = null;

    this.importLoading = false;
    this.importResult = null;

    this.importSettings = {
      headerLength: 1,
      accountId: provisionService.selectedAccountId
    };

    eventService.subscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );

    this.onPoiFieldChanged = this.onPoiFieldChanged.bind(this);
  }

  detached() {
    eventService.unsubscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  @computedFrom('fileMappings')
  get disablePreview() {
    if (!Array.isArray(this.fileMappings)) return false;

    let keys = this.fileMappings.map(f => f.key);
    return (
      keys.indexOf('address') < 0 &&
      (keys.indexOf('latitude') < 0 || keys.indexOf('longitude') < 0)
    );
  }

  //aurelia bug on firefox causing issues [https://github.com/aurelia/binding/issues/314]
  // @computedFrom("fileInput")
  get file() {
    return this.fileInput && this.fileInput[0];
  }

  onAccountChanged = id => {
    this.setImportState(false, null, true);
    this.setImportState(false, null);
    this.importSettings.accountId = id;
  };

  onFileUpload() {
    if (this.file) {
      this.loadingUpload = true;
      this.fileMappings = null;

      this.poiService
        .analyseCSV(this.file)
        .then(mappings => {
          this.fileMappings = this.buildFileMappings(mappings);
          this.loadingUpload = false;
        })
        .catch(() => {
          this.loadingUpload = false;
        });
    }
  }

  onImportClick(preview) {
    if (!preview) {
      const message = `Are you sure you wish to import the POI to the selected account?`;

      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: { title: 'Confirm', message },
          lock: true
        })
        .whenClosed(result => {
          if (result.wasCancelled) return;

          this.import(preview);
        });
      return;
    }

    this.import(preview);
  }

  onPoiFieldChanged(fileMapping, item) {
    fileMapping.key = item.value;
    this.fileMappings = this.buildFileMappings(this.fileMappings);
  }

  //helpers
  import(preview) {
    const checkImport = id => {
      this.poiService
        .getImportCSV(id)
        .then(result => {
          if (result.status !== 'complete') {
            delayCheckImport(id);
            return;
          }

          this.setImportState(false, result, preview);
        })
        .catch(() => {});
    };
    const delayCheckImport = id => setTimeout(() => checkImport(id), 3000);

    //
    let mappings = this.fileMappings
      .filter(f => f.key)
      .map(f => ({ key: f.key, field: f.field }));
    let settings = this.importSettings;

    this.setImportState(true, null, preview);

    this.poiService
      .queueImportCSV(this.file, { preview, mappings, settings })
      .then(result => {
        this.setImportState(true, result, preview);
        delayCheckImport(result.id);
      })
      .catch(() => {
        this.setImportState(false, null, preview);
      });
  }

  setImportState(loading, result, preview) {
    this.importLoading = loading;

    if (preview) {
      this.previewResult = result;
    } else {
      this.importResult = result;
    }
  }

  buildFileMappings(mappings) {
    return mappings.map(m => ({
      ...m,
      poiFields: this.buildPOIfields(m, mappings)
    }));
  }

  buildPOIfields(mapping, mappings) {
    let list = POI_FIELDS.map(f => ({
      name: f,
      value: f,
      selected: f === mapping.key,
      disabled: f !== mapping.key && !!mappings.find(m => m.key === f)
    }));

    return [{ name: 'POI Field', default: true }].concat(list);
  }
}
