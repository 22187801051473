import { inlineView } from 'aurelia-framework';
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ProvisionTranslations {
  configureRouter(config, router) {
    const routes = [{
      route: '',
      name: 'translations',
      title: '',
      moduleId: PLATFORM.moduleName('./translations-list')
    },{
      route: ':id',
      name: 'translation-details',
      title: 'Translation details',
      moduleId: PLATFORM.moduleName('./translation-details')
    }];
    config.map(routes);
  }
}
