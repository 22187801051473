import {
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import './share-dialog.scss';

@inject(DialogController)
export class ShareDialog {
  constructor(_DialogController) {
    this.dialogController = _DialogController;

    this.title = 'send_by_email';
    this.cancel = 'cancel';
    this.confirm = 'send';
    this.emailAddresses = [];
  }

  activate(model) {
    if (model) {
      if (model.title || model.title === null) {
        this.title = model.title;
        this.question = model.question || this.question;
      }

      this.cancel = model.cancel || this.cancel;
      this.confirm = model.confirm || this.confirm;
    }
  }

  onCancel() {
    this.dialogController.cancel()
  }

  onConfirm() {
    var data = {
      emailAddresses: this.emailAddresses
    };
    this.dialogController.ok(data);
  }
}
