/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import provisionService from 'services/api/provisionService';
import userService, {
  Account
} from 'services/api/userService';

import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  Router
} from 'aurelia-router';

import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class AccountDetails {
  static inject() {
    return [DialogService, Router, NewInstance.of(ValidationController)];
  }

  constructor(_DialogService, _Router, _ValidationController) {
    this.dialogService = _DialogService;
    this.router = _Router;
    this.validationController = _ValidationController;

    //
    this.userService = userService;
    this.provisionService = provisionService;

    this.rules = ValidationRules.ensure(a => a.name)
      .required()
      .minLength(2).withMessageKey('tooShort')
      //REFACTOR
      .ensure(a => a.ownerEmail)
      .email()
      .required()
      .when(a => !!a.ownerName)
      .ensure(a => a.ownerName)
      .required()
      .when(a => !!a.ownerEmail)
      //
      .ensure(o => o.ownerPassword)
      .required()
      .when(o => o.requirePassword)
      .minLength(8)
      .when(o => o.requirePassword)
      .withMessageKey('tooShort')
      .satisfies((value, o) => o.confirmPassword === value)
      .when(o => o.requirePassword)
      .withMessageKey('passwordsMatch').rules;

    this.toggleCreatePassword = false;
    this.confirmPassword = null;

    this.account = null;
    this.permissionsList = null;
    this.loading = false;
    this.errorMessage = null;
  }

  activate(params, cfg) {
    let {
      account,
      permissionsList
    } = cfg.settings;
    this.loadData(params.id, account, permissionsList);
  }

  loadData(id, account, permissionsList) {
    this.loading = true;
    this.loadPermissionsList(permissionsList)
      .then(permissions => {
        this.permissionsList = permissions;

        //prefill form
        this.account = account;

        return id > 0 ?
          this.loadAccount(id) :
          new Account({
            ...account,
            permissions: permissions.map(p => p.permission)
          });
      })
      .then(account => {
        this.account = account;
        this.loading = false;
      })
      .catch(() => {
        this.navigateBack();
      });
  }

  loadPermissionsList(permissions) {
    if (permissions) {
      return Promise.resolve(permissions);
    }
    return this.userService.getPermissionsList();
  }

  loadAccount(id) {
    return this.provisionService.getAccounts(id).then(([account]) => account);
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      //refactor
      //owner account needs validation, but goes inside owner object
      if (this.account.ownerEmail || this.account.ownerName) {
        this.account.owner = {
          name: this.account.ownerName,
          username: this.account.ownerEmail,
          password: this.account.requirePassword ?
            this.account.ownerPassword : undefined
        };
      }
      delete this.account.requirePassword;
      delete this.account.confirmPassword;

      //when hitting /new route directly this is empty
      this.account.resellerId =
        this.account.resellerId || this.provisionService.selectedResellerId;

      return this.provisionService
        .updateAccount(this.account)
        .then(() => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    //refactor
    //validations must be run in same obj as form, otherwise erros inputs do not work
    this.account.requirePassword = this.toggleCreatePassword;
    this.account.confirmPassword = this.confirmPassword;

    return this.validationController
      .validate({
        object: this.account,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onCancel() {
    this.navigateBack();
  }

  toggleEnabled() {
    let title = !this.account.isEnabled ? 'restore_account' : 'suspend_account';
    let message = !this.account.isEnabled ? 'restore_account_message' : 'suspend_account_message';
    let confirm = !this.account.isEnabled ? 'restore' : 'suspend';

    this.openPrompt(title, true, message, confirm).then(confirm => {
      if (!confirm) return;
      this.account.isEnabled = !this.account.isEnabled;
      this.onSave();
    });
  }

  openPrompt(title, question, message, confirm) {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  navigateBack() {
    this.router.navigateToRoute('accounts');
  }
}
