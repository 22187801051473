import { inlineView } from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ProvisionAccounts {

  configureRouter(config,router) {
    config.map([{
      route: '',
      name: 'accounts',
      title: '',
      moduleId: PLATFORM.moduleName('./accounts-list')
      },{
        route: ':id',
        name: 'account-details',
        title: 'Account details',
        moduleId: PLATFORM.moduleName('./account-details')
      }
    ])
  }
}
