/** @format */

import { computedFrom } from 'aurelia-framework';

import provisionService from 'services/api/provisionService';
import userService from 'services/api/userService';

import uiService from 'services/uiService';
import eventService, { EventsList } from 'services/eventService';
import PLATFORM from 'aurelia-pal';

import './provision.scss';

export class Provision {
  constructor() {
    this.provisionService = provisionService;

    //
    this.userService = userService;
    this.uiService = uiService;
    //
    this.onResellerChanged = this.onResellerChanged.bind(this);
    this.onAccountChanged = this.onAccountChanged.bind(this);

    this.enabledAccountFilter = null;
    this.showResellerFilter = this.userService.hasRole('admin');
    this.showAccountFilter = this.userService.hasRole('reseller');
  }

  configureRouter(config, router) {
    this.router = router;

    const setupRoutes = user => {
      const routes = [];

      if (user.isSuperAdmin) {
        routes.push(this.newRoute('resellers', false, 'Resellers', PLATFORM.moduleName('./pages/resellers/provision-resellers')));
      }
      if (user.isReseller) {
        routes.push(this.newRoute('accounts', false, 'Accounts', PLATFORM.moduleName('./pages/accounts/provision-accounts')));
        routes.push(this.newRoute('demo_passes', false, 'Demo Passes', PLATFORM.moduleName('./pages/demos/provision-demos')));
      }

      routes.push(
        this.newRoute(
          'users',
          true,
          'Users',
          PLATFORM.moduleName('components/manage-users-page/manage-users-page')
        )
      );
      routes.push(this.newRoute('devices', true, 'Devices', PLATFORM.moduleName('./pages/devices/provision-devices')));

      if (user.isSuperAdmin) {
        routes.push(this.newRoute('import-poi', true, 'Import POI', PLATFORM.moduleName('./pages/import-poi/provision-import-poi')));
        routes.push(this.newRoute('translations', false, 'Translations', PLATFORM.moduleName('./pages/translations/provision-translations')));
      }

      routes.push({ route: '', redirect: 'devices' });
      config.map(routes);
    };

    this.userService.getSelf().then(user => {
      setupRoutes(user);

      if (this.uiService.impersonation) {
        this.provisionService.selectedAccountId = user.accountId;
        this.provisionService.selectedResellerId = user.resellerId;
      }

      //wait for navigation to update visuals state
      setTimeout(() => {
        let active = this.router.navigation.find(x => x.isActive);
        this.enabledAccountFilter =
          active && active.config.settings.enabledAccountFilter;
      });
    });

    config.mapUnknownRoutes(() => {
      this.router.navigateToRoute('provisioning');
    });
  }

  newRoute(name, enabledAccountFilter = false, title, moduleId) {
    return {
      route: name,
      name,
      title: title,
      moduleId: moduleId || `./pages/${name}/provision-${name}`,
      nav: true,
      settings: {
        enabledAccountFilter
      }
    };
  }

  @computedFrom('provisionService.selectedResellerId')
  get selectedResellerId() {
    return this.provisionService.selectedResellerId;
  }

  goTo(routeName) {
    let route = this.router.routes.find(x => x.name === routeName);
    this.enabledAccountFilter =
      (route && route.settings.enabledAccountFilter) || false;
    this.router.navigateToRoute(routeName);
  }

  get pageSectionItems() {
    return this.router.navigation.filter(n => !n.config.subsection);
  }

  onResellerChanged(value, old) {
    this.provisionService.selectedResellerId = value;

    eventService.publish(EventsList.ProvisionResellerChanged, value);
    this.validateRoute(!!old);
  }

  onAccountChanged(value, old) {
    this.provisionService.selectedAccountId = value;

    eventService.publish(EventsList.PrivisionAccountChanged, value);
    this.validateRoute(!!old);
  }

  validateRoute(validate) {
    let config = this.router.currentInstruction.config;
    //is active route is a form route (which can depend on account/reseller filter)
    let isFormRoute = config.hasChildRouter && validate;
    if (isFormRoute) {
      //go to immediate parent route
      this.router.navigateToRoute(config.name);
      // this.router.navigateBack();
    }
  }
}
