/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import provisionService from 'services/api/provisionService';

import {
  Reseller
} from 'services/api/userService';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';

import {
  Router
} from 'aurelia-router';

export class ResellerDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController)];
  }

  constructor(_Router, _ValidationController) {
    this.validationController = _ValidationController;
    this.router = _Router;
    //
    this.provisionService = provisionService;
    //

    this.rules = ValidationRules.ensure(r => r.name)
      .required()
      .minLength(2).withMessageKey('tooShort').rules;

    this.reseller = null;
    this.loading = false;
    this.errorMessage = null;

    this.imagePickerFile = undefined;

    this.onImagePickerChanged = this.onImagePickerChanged.bind(this);
  }

  @computedFrom('reseller')
  get theme() {
    if (this.reseller) {
      return (this.reseller.settings || {}).theme;
    }
  }

  activate(params, cfg) {
    let {
      reseller
    } = cfg.settings;
    this.reseller = reseller || new Reseller();
    this.loadReseller(params.id);
  }

  loadReseller(id) {
    if (id > 0) {
      this.loading = true;
      this.provisionService
        .getResellers(id)
        .then(([reseller]) => {
          if (!reseller) {
            this.navigateBack();
            return;
          }
          this.reseller = reseller;
          this.loading = false;
        })
        .catch(() => this.navigateBack());
    }
  }

  navigateBack() {
    this.router.navigateToRoute('resellers');
  }

  onDisable() {
    this.reseller.isEnabled = false;
    this.save().then(item => {
      if (!item) return;
      this.navigateBack();
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onSave() {
    this.save().then(item => {
      if (!item) return;
      this.navigateBack();
    });
  }

  save() {
    return this.onValidate().then(result => {
      if (!result.valid) throw (result);

      this.loading = true;
      const logo = this.imagePickerFile;

      return this.provisionService
        .updateReseller(this.reseller)
        .then(reseller => {
          if (logo !== undefined) {
            let {
              id
            } = reseller;
            return this.provisionService
              .setResellerLogo(id, logo)
              .then(() => reseller);
          }
          this.loading = false;
          return reseller;
        }).catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.reseller,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onImagePickerChanged(file) {
    this.imagePickerFile = file;
  }
}
