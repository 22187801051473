/** @format */

import provisionService from 'services/api/provisionService';
import { Router } from 'aurelia-router';

export class ProvisionResellerList {
  static inject() {
    return [Router];
  }

  constructor(_Router) {
    this.router = _Router;
    this.route = {};
    //
    this.provisionService = provisionService;
    //
    this.isLoading = false;
    this.detailsLoading = false;
    this.resellers = [];
    this.selectedItem = null;
    this.errorMessage = '';

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.itemSelect = this.itemSelect.bind(this);
  }

  //this runs also when returnning from details route
  attached() {
    this.errorMessage = '';
    this.isLoading = true;
    this.provisionService.getResellers().then(res => {
      this.isLoading = false;
      this.resellers = res;
    });
  }

  activate(params, config) {
    this.route = config;
  }

  onCountryChanged(value) {
    if (this.selectedItem) {
      this.selectedItem.country = value.id;
    }
  }

  itemSelect(item) {
    this.goToDetails(item);
  }

  onNewClick() {
    this.goToDetails();
  }

  goToDetails(reseller) {
    let route = 'reseller-details';
    this.router.routes.find(x => x.name === route).settings.reseller = reseller;
    this.router.navigateToRoute(route, { id: reseller ? reseller.id : 'new' });
  }
}
